console.log('Ready javascript');

document.addEventListener("touchstart", function(){}, true);

$('.js-eventClick__this__e-plus-info__content span').click(function(event){
  whichLink=$('.js-eventClick__this__e-plus-info__content span').index(this);
  $('.js-eventClick__this__e-plus-info__content').eq(whichLink).toggleClass('is-active');
  event.preventDefault();
});

// //Cookie disclamair_1
// jQuery( document ).ready(function( $ ) {
//   function readCookie(name) {
//     var nameEQ = name + "=";
//     var ca = document.cookie.split(';');
//     for(var i=0;i < ca.length;i++) {
//       var c = ca[i];
//       while (c.charAt(0)==' ') c = c.substring(1,c.length);
//       if (c.indexOf(nameEQ) == 0) {
//         return decodeURIComponent( c.substring(nameEQ.length,c.length) );
//       }
//     }
//     return null;
//   }
//   var miCookie = readCookie( "name_cookie" );
//   if (miCookie!=='cookie_active_info') {
//     setTimeout(function() {
//       $('#body').addClass('is-active-popup');
//       $('#body').append('<div id="popup"></div>');   
//       $( "#popup" ).load( "content-ajax/disclamair.php" );
//     }, 0);
//   }
// });

// if (document.getElementById('js__c-form')){
//   var checkboxForm     = document.querySelectorAll('.c-form input[name=job-departament]');
//   var plusForm         = document.querySelectorAll('#js-c-form__plus-form, #js-c-form__btn-before');
//   var form__btn_before = document.getElementById('js-c-form__btn-before');
//   var form__title      = document.getElementById('js-c-form__title');

//   document.getElementById('js__c-form').addEventListener('change', function() {

//     if (physicions.checked || distribution.checked || patient.checked) {

//       var hiddenForm = function(){

//         plusForm.forEach( function(element, index) {
//           element.classList.add('e-fade-in');
//           element.classList.remove('dn');
//         });
//         document.getElementById('js-c-form__items-check').classList.add('dn');
        
//         if(physicions.checked) {
//           form__title.innerHTML = physicions.dataset.title;
//         } 
//         if(distribution.checked) {
//           form__title.innerHTML = distribution.dataset.title;
//         }

//         if(patient.checked) {
//           form__title.innerHTML = patient.dataset.title;
//         }

//       };
//       setTimeout(hiddenForm, 300);
//     } 
//   });



//   form__btn_before.addEventListener('click',function(){
//     plusForm.forEach( function(element, index) {
//       element.classList.remove('e-fade-in');
//       element.classList.add('dn');
//     });
//     document.getElementById('js-c-form__items-check').classList.remove('dn');
//     form__title.innerHTML = '';
//     physicions.checked    = false;
//     distribution.checked  = false;
//     patient.checked       = false;
//   });
// }


$('.c-carousel-testimonials__container').slick({
  dots: false,
  infinite: true,
  speed: 300,
  autoplaySpeed: 30000,
  slidesToShow: 4,
  autoplay: true,
  variableWidth: true,
  centerMode: true,
  // responsive: [
  // {
  //   breakpoint: 1400,
  //   settings: {
  //     slidesToShow: 2,
  //     slidesToScroll: 2
  //   }
  // },
  // {
  //   breakpoint: 700,
  //   variableWidth: false,
  //   settings: {
  //     slidesToShow: 1,
  //     slidesToScroll: 1
  //   }
  // }
  // ]
});
if (document.getElementsByClassName('c-carousel-testimonials__comments')[0]) {


  var carousel__comments = $('.c-carousel-testimonials__comments');
  carousel__comments[0].classList.remove('dn');
  $('.c-carousel-testimonials__container').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    // console.log(nextSlide);
    carousel__comments.addClass('dn e-animation-text-focus-in-100');
    carousel__comments[nextSlide].classList.remove('dn');
  });
}






$('#js-eventHover__c-access__item-btn-1 , .js-eventHover__c-access__item-btn-1').hover (
                                                 function() {
                                                  $('.c-access' ).addClass('is-active-1');
                                                }, function() {
                                                  $('.c-access' ).removeClass('is-active-1');
                                                });

$('#js-eventHover__c-access__item-btn-2 , .js-eventHover__c-access__item-btn-2').hover (
                                                 function() {
                                                  $('.c-access' ).addClass('is-active-2');
                                                }, function() {
                                                  $('.c-access' ).removeClass('is-active-2');
                                                });



$( window).scroll(function() {
  if ($(window).scrollTop()> 300 ) {
    $('#header').addClass('is-active');
  } else  {
    $('#header').removeClass('is-active');
  }
});



var heighPage = 0;
 // var sliderAdaptiveHeightMobile = function() {
 //    //Re-init - resize soluction recomendation
 //    $carousel.flickity('resize');
 //    console.log('Reinit / Play :: Slider Home');
 //  };

 $('#js-eventClick-toggle--header__btn-menu').click(function(){
  // heighPage = window.pageYOffset;
  
  this.classList.toggle('is-active');
  
  if (this.classList.contains('is-active')==true){

    heighPage = window.pageYOffset;
    $('html').addClass('is-active-menu');
    window.scrollTo( 0,0);
    //sliderAdaptiveHeightMobile();

  } else {

    $('html').removeClass('is-active-menu');
    console.log(heighPage);
    window.scrollTo( 0,heighPage);
    //sliderAdaptiveHeightMobile();
  }
});

 $("a").on('click', function(event) {
  if (this.hash !== "") {
    var hash = this.hash;
    var headerHeight = document.getElementsByTagName("header");
    if (headerHeight[0].classList != "fixed"){
      var topPage = $(hash).offset().top;
    } else {
      var topPage = $(hash).offset().top;
    }
    $('html, body').animate({
      scrollTop: topPage
    }, 1000, function(){
     window.location.hash = hash;
     var archos = window.location.hash.substr(1);
   });
  } 
});

 var count_items = $('.c-carousel .item').length;

// console.log(count_items);
// if (count_items>4) {
  var $carousel = $('.js-carousel-flickity').flickity({
    cellAlign: 'left',
    contain: true,
    prevNextButtons: false,
    pageDots: false,
    wrapAround: true,
    groupCells:1,
    autoPlay: 2800,
    imagesLoaded: false,
    percentPosition: true,

    on: {
      ready: function() {
      },
      change: function( index ) {
        this.player.play();
      },
      dragMove: function( event, pointer, moveVector ){
        var $link = $('.js-carousel-flickity').find('a').addClass('e-pointer-events--none');
      },
      dragEnd: function( event, pointer ){
        var $link = $('.js-carousel-flickity').find('a').removeClass('e-pointer-events--none');
      },
      staticClick: function(event, pointer, cellElement, cellIndex){
        if (!cellElement) {
          return;
        }
        var $zoomurl = $(cellElement).find('img').attr('src');
        $(cellElement).find('a').removeClass('e-pointer-events--none');
      }
    }
  });
// }

var count_items = $('.js-carousel-flickity--news .item').length;
if (count_items>3) {
  var $carousel = $('.js-carousel-flickity--news').flickity({
    cellAlign: 'center',
    contain: true,
    prevNextButtons: true,
    pageDots: true,
    wrapAround: true,
    groupCells:1,
    autoPlay: 4000,
    imagesLoaded: true,
    percentPosition: true,
    on: {
      ready: function() {
      },
      change: function( index ) {
        this.player.play();
      },
      dragMove: function( event, pointer, moveVector ){
        var $link = $('.js-carousel-flickity').find('a').addClass('e-pointer-events--none');
      },
      dragEnd: function( event, pointer ){
        var $link = $('.js-carousel-flickity').find('a').removeClass('e-pointer-events--none');
      },
      staticClick: function(event, pointer, cellElement, cellIndex){
        if (!cellElement) {
          return;
        }
        var $zoomurl = $(cellElement).find('img').attr('src');
        $(cellElement).find('a').removeClass('e-pointer-events--none');
      }
    }
  });
}




var count_items = $('.js-carousel-flickity--personal .item').length;
if (count_items>4) {
  var $carousel = $('.js-carousel-flickity--personal').flickity({
    cellAlign: 'left',
    contain: true,
    prevNextButtons: false,
    pageDots: true,
    wrapAround: true,
    groupCells:1,
    autoPlay: 30000,
    imagesLoaded: true,
    percentPosition: true,
    //adaptiveHeight: true,
    on: {
      ready: function() {
      },
      change: function( index ) {
        this.player.play();
      },
      dragMove: function( event, pointer, moveVector ){
        var $link = $('.js-carousel-flickity--personal').find('a').addClass('e-pointer-events--none');
      },
      dragEnd: function( event, pointer ){
        var $link = $('.js-carousel-flickity--personal').find('a').removeClass('e-pointer-events--none');
      },
      staticClick: function(event, pointer, cellElement, cellIndex){
        if (!cellElement) {
          return;
        }
        var $zoomurl = $(cellElement).find('img').attr('src');
        $(cellElement).find('a').removeClass('e-pointer-events--none');
      }
    }
  });
}


var count_items = $('.js-carousel-flickity--products .item').length;
if (count_items) {
  var $carousel = $('.js-carousel-flickity--products').flickity({
    cellAlign: 'left',
    contain: true,
    prevNextButtons: true,
    pageDots: true,
    wrapAround: false,
    groupCells:1,
    autoPlay: 30000,
    imagesLoaded: true,
    percentPosition: true,
    adaptiveHeight: true,
    on: {
      ready: function() {
      },
      change: function( index ) {
        this.player.play();
      },
      dragMove: function( event, pointer, moveVector ){
        var $link = $('.js-carousel-flickity--products').find('a').addClass('e-pointer-events--none');
      },
      dragEnd: function( event, pointer ){
        var $link = $('.js-carousel-flickity--products').find('a').removeClass('e-pointer-events--none');
      },
      staticClick: function(event, pointer, cellElement, cellIndex){
        if (!cellElement) {
          return;
        }
        var $zoomurl = $(cellElement).find('img').attr('src');
        $(cellElement).find('a').removeClass('e-pointer-events--none');
      }
    }
  });
}



